<template>
  <div>
    <div
      v-if="show"
      class="d-flex justify-content-center align-items-center"
      style="height: 50vh;"
    >
      <b-spinner
        label="Spinning"
      />
    </div>
    <b-card
      v-if="!show"
    >
      <b-row>
        <!-- <b-col
        md="3"
        class="mb-1"
      >
        <b-input-group>
          <b-form-input placeholder="Фильтр..." />
          <b-input-group-append>
            <b-button variant="outline-primary">
              Поиск
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col> -->
        <b-col
          md="9"
          class="mb-1"
        >
          <b-button
            v-ripple.400="'rgba(255, 159, 67, 0.15)'"
            v-b-modal.modal-add
            variant="outline-warning"
          >
            Добавить
          </b-button>
        </b-col>
      </b-row>
      <b-table
        responsive="sm"
        :items="promoCodes"
        :fields="tableColumns"
      >
        <template v-slot:cell(actions)="data">
          <b-button
            v-b-modal.modal-warning-edit
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="btn-icon mr-1"
            @click="edit(data.item)"
          >
            <feather-icon icon="Edit2Icon" />
          </b-button>
          <b-button
            variant="gradient-danger"
            class="btn-icon"
            @click="confirmDelete(data.item)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
      </b-table>
      <b-modal
        id="modal-add"
        ok-variant="warning"
        ok-title="Сохранить"
        cancel-title="Закрыть"
        modal-class="modal-warning"
        centered
        title="Добавление"
        @hidden="resetModal"
        @ok="add"
      >
        <validation-observer ref="simpleRules">
          <b-col cols="12">
            <label for="input-default">Промокод</label>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Промокод"
                rules="required"
              >
                <b-form-input
                  id="input-default"
                  v-model="promoCode.code"
                  placeholder="test"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Тип промокода"
              label-for="largeInput"
            >
              <b-form-select
                v-model="promoCode.type"
                :options="typeOptions"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <label for="input-default">Значение</label>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Значение"
                rules="required"
              >
                <b-form-input
                  id="input-default"
                  v-model="promoCode.value"
                  placeholder="12"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Основные категории"
                rules="required"
              >
                <label for="Quantity-default">Основные категории</label>
                <v-select
                  v-model="promoCode.main_category_ids"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  value-field="id"
                  label="name"
                  :options="mainCategories"
                  :reduce="category => category.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Категории"
                rules="required"
              >
                <label for="Quantity-default">Категории</label>
                <v-select
                  v-model="promoCode.category_ids"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  value-field="id"
                  label="name"
                  :options="categories"
                  :reduce="category => category.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  name="Бренды"
                  rules="required"
              >
                <label for="Quantity-default">Бренды</label>
                <v-select
                    v-model="promoCode.brand_ids"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    value-field="id"
                    label="name"
                    :options="brands"
                    :reduce="brand => brand.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Один раз для 1 пользователя?"
              label-for="once_use"
              for="once_use"
            >
              <b-form-checkbox
                id="once_use"
                v-model="promoCode.once_use"
                class="mr-0 mt-50"
                name="once_use"
                switch
                inline
              />
            </b-form-group>
          </b-col>
        </validation-observer>
        <b-col cols="12">
          <label for="example-datepicker">Срок до</label>
          <b-form-datepicker
            id="example-datepicker"
            v-model="promoCode.expires_at"
            class="mb-2"
          />
        </b-col>
      </b-modal>

      <b-modal
        id="modal-warning-edit"
        ok-variant="warning"
        ok-title="Сохранить"
        cancel-title="Закрыть"
        modal-class="modal-warning"
        centered
        title="Редактировать"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <validation-observer ref="simpleRules">
          <b-form>
            <b-col cols="12">
              <label for="input-default">Промокод</label>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Промокод"
                  rules="required"
                >
                  <b-form-input
                    id="input-default"
                    v-model="promoCode.code"
                    placeholder="test"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Тип промокода"
                label-for="largeInput"
              >
                <b-form-select
                  v-model="promoCode.type"
                  :options="typeOptions"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <label for="input-default">Значение</label>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Значение"
                  rules="required"
                >
                  <b-form-input
                    id="input-default"
                    v-model="promoCode.value"
                    placeholder="12"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Основные категории"
                  rules="required"
                >
                  <label for="Quantity-default">Основные категории</label>
                  <v-select
                    v-model="promoCode.main_category_ids"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    value-field="id"
                    label="name"
                    :options="mainCategories"
                    :reduce="category => category.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Категории"
                  rules="required"
                >
                  <label for="Quantity-default">Категории</label>
                  <v-select
                    v-model="promoCode.category_ids"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    value-field="id"
                    label="name"
                    :options="categories"
                    :reduce="category => category.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="Бренды"
                    rules="required"
                >
                  <label for="Quantity-default">Бренды</label>
                  <v-select
                      v-model="promoCode.brand_ids"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      value-field="id"
                      label="name"
                      :options="brands"
                      :reduce="brand => brand.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Один раз для 1 пользователя?"
                label-for="once_use"
                for="once_use"
              >
                <b-form-checkbox
                  id="once_use"
                  v-model="promoCode.once_use"
                  class="mr-0 mt-50"
                  name="once_use"
                  switch
                  inline
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <label for="example-datepicker">Срок до</label>
              <b-form-datepicker
                id="example-datepicker"
                v-model="promoCode.expires_at"
                class="mb-2"
              />
            </b-col>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import {
  BForm,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BModal,
  BRow,
  BTable,
  VBModal,
  BFormCheckbox,
  BSpinner,
  BFormDatepicker,
} from 'bootstrap-vue'
import axios from '@axios'
import vSelect from 'vue-select'
import { $themeConfig } from '@themeConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { codeSeparated } from './Pagination/code'

export default {
  components: {
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BFormSelect,
    BCard,
    BModal,
    BRow,
    BCol,
    BFormCheckbox,
    BSpinner,
    BFormDatepicker,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      IMG_URL: $themeConfig.app.IMG_URL,
      passValue: '',
      username: '',
      required,
      promoCodes: [],
      show: true,
      tableColumns: [
        {
          key: 'id',
          label: '#',
          sortable: true,
        },
        {
          key: 'code',
          label: 'Промокод',
          sortable: true,
        },
        {
          key: 'type',
          label: 'Тип',
          sortable: true,
          class: 'comment',
        },
        {
          key: 'value',
          label: 'Значение',
          sortable: true,
        },
        {
          key: 'expires_at',
          label: 'Срок',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'Действия',
        },
      ],
      promoCode: {
        id: '',
        code: '',
        type: null,
        value: '',
        once_use: false,
        expires_at: '',
        main_category_ids: [],
        category_ids: [],
        brand_ids: [],
      },
      typeOptions: [
        { value: null, text: 'Выберите тип' },
        { value: 'fixed', text: 'Фиксированная (TJS)' },
        { value: 'percent', text: 'Процентная' },
      ],
      mainCategories: [],
      categories: [],
      brands: [],
      codeSeparated,
      currentPage: 1,
      rows: 50,
      perPage: 15,
    }
  },
  mounted() {
    this.getPromoCodes()
    this.getMCategory()
    this.getCategories()
    this.getBrands()
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line no-alert
          alert('login successfully')
        }
      })
    },
    getMCategory() {
      axios.get(`${$themeConfig.app.API}v2/admin/main-category`)
        .then(res => {
          this.mainCategories = res.data.data
        })
        .catch(er => {
          console.log(er)
        })
    },
    getCategories() {
      this.show = true
      axios.get(`${$themeConfig.app.API}v2/admin/categories`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
        .then(res => {
          this.categories = res.data
          this.show = false
        })
        .catch(er => {
          console.log(er)
          this.show = false
        })
    },
    getBrands() {
      this.show = true
      axios.get(`${$themeConfig.app.API}brands`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
          .then(res => {
            this.brands = res.data
            this.show = false
          })
          .catch(er => {
            console.log(er)
            this.show = false
          })
    },
    getPromoCodes() {
      this.show = true
      axios.get(`${$themeConfig.app.API}v2/admin/promocodes`)
        .then(res => {
          this.promoCodes = res.data.data
          this.show = false
        })
        .catch(er => {
          console.log(er)
          this.show = false
        })
    },
    async add() {
      const myFormData = new FormData()
      myFormData.append('code', this.promoCode.code)
      myFormData.append('type', this.promoCode.type)
      myFormData.append('value', this.promoCode.value)
      myFormData.append('once_use', this.promoCode.once_use ? 1 : 0)
      myFormData.append('expires_at', this.promoCode.expires_at)

      this.promoCode.main_category_ids.forEach(mainCategoryId => {
        myFormData.append('main_category_ids[]', mainCategoryId)
      })
      this.promoCode.category_ids.forEach(categoryId => {
        myFormData.append('category_ids[]', categoryId)
      })
      this.promoCode.brand_ids.forEach(brandId => {
        myFormData.append('brand_ids[]', brandId)
      })

      await axios.post(`${$themeConfig.app.API}v2/admin/promocodes`, myFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
        .then(() => {
          this.addStatus = true
          this.getPromoCodes()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              text: 'Запись успешно добавлен',
              icon: 'CheckSquareIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          this.addStatus = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              text: e.message,
              icon: 'CheckSquareIcon',
              variant: 'danger',
            },
          })
        })
    },
    resetModal() {
      this.promoCode.id = ''
      this.promoCode.code = ''
      this.promoCode.type = null
      this.promoCode.value = ''
      this.promoCode.once_use = false
      this.promoCode.expires_at = ''
      this.promoCode.main_category_ids = []
      this.promoCode.category_ids = []
    },
    // eslint-disable-next-line no-unused-vars
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      // bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleEdit()
    },
    async handleEdit() {
      const myFormData = new FormData()
      myFormData.append('code', this.promoCode.code)
      myFormData.append('type', this.promoCode.type)
      myFormData.append('value', this.promoCode.value)
      myFormData.append('once_use', this.promoCode.once_use ? 1 : 0)
      myFormData.append('expires_at', this.promoCode.expires_at)
      myFormData.append('_method', 'PUT')

      this.promoCode.main_category_ids.forEach(mainCategoryId => {
        myFormData.append('main_category_ids[]', mainCategoryId)
      })
      this.promoCode.category_ids.forEach(categoryId => {
        myFormData.append('category_ids[]', categoryId)
      })
      this.promoCode.brand_ids.forEach(brandId => {
        myFormData.append('brand_ids[]', brandId)
      })
      // // Edit request
      if (this.promoCode.id !== '') {
        await axios.post(`${$themeConfig.app.API}v2/admin/promocodes/${this.promoCode.id}`, myFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            // Accept: 'application/json',
          },
        })
          .then(() => {
            this.getPromoCodes()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Успешно',
                text: 'Данные были изменены',
                icon: 'CheckSquareIcon',
                variant: 'success',
              },
            })
          })
          .catch(e => {
            console.log(e)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ошибка',
                text: e.message,
                icon: 'CheckSquareIcon',
                variant: 'danger',
              },
            })
          })
      }

      // // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
    edit(data) {
      this.promoCode.id = data.id
      this.promoCode.code = data.code
      this.promoCode.type = data.type
      this.promoCode.value = data.value
      this.promoCode.once_use = !!data.once_use
      this.promoCode.expires_at = data.expires_at
      this.promoCode.main_category_ids = data.main_categories.map(({ id }) => id)
      this.promoCode.brand_ids = data.brands.map(({ id }) => id)
      this.promoCode.category_ids = data.categories.map(({ id }) => id)
    },
    confirmDelete(data) {
      this.$bvModal
        .msgBoxConfirm('После удаления данные восстановить нельзя будет!', {
          title: 'Вы уверены что хотите удалить?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Удалить',
          cancelTitle: 'Отменить',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            axios.delete(`${$themeConfig.app.API}v2/admin/promocodes/${data.id}`)
              .then(() => {
                this.getPromoCodes()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Успешно',
                    text: 'Данные были удалены',
                    icon: 'CheckSquareIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(e => {
                console.log(e)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Ошибка',
                    text: e.message,
                    icon: 'CheckSquareIcon',
                    variant: 'dander',
                  },
                })
              })
          }
        })
    },
    // getBase64(file) {
    //   // eslint-disable-next-line no-unused-vars
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader()
    //     reader.onloadend = () => {
    //       resolve(reader.result)
    //     }
    //     reader.readAsDataURL(file)
    //   })
    // },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
